<template lang='pug'>
head-meta(title-page="Способы оплаты продукции в Музее «Шоколадный Выборг»",
  description="Выберите удобные вам способы оплаты при покупке в магазине или на сайте Музея «Шоколадный Выборг»")
the-payment
contacts-line
</template>

<script>
import ThePayment from '../components/payment/ThePayment'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'
export default {
  name: 'PaymentPage',
  components: { ContactsLine, HeadMeta, ThePayment }
}
</script>
