<template lang='pug'>
article.payment
  .payment__cont.cont
    h1.payment__caption.caption Оплата
    p.payment__text.text-big
      | Выберите удобные вам способы оплаты при покупке в магазине или на сайте Музея
    h2.payment__title.title Оплата в магазине
    p.payment__text.text-big
      | Действует наличный и безналичный расчёт при оплате покупки в магазине Музея «Шоколадный Выборг» по адресу: г. Выборг, ул. Штурма, 1А
    h2.payment__title.title Оплата на сайте
    p.payment__text.payment__text_middle.text-big
      | На сайте действует оплата банковскими картами
    .payment__icons
      img.payment__icon(v-for="(icon, i) in images", :src="`${icon.path}.svg`", :key="i", :alt="icon.alt", :title="icon.alt", width="85", height="60")
    p.payment__text.text-big
      | Для оплаты вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача
      | информации проходит в защищённом режиме с протоколом шифрования SSL. Если ваш банк поддерживает технологию
      | безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, может
      | потребоваться ввод специального пароля.
    p.payment__text.text-big
      | Сайт поддерживает 256-битное шифрование. Конфиденциальность персональной информации обеспечивает ПАО СБЕРБАНК.
      | Информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ.
      | Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем
      | МИР, Visa Int., MasterCard Europe Sprl, JCB.
    p.payment__text.payment__text_attention.text-big
      | Менеджер свяжется с вами после проверки оплаты.
    p.payment__text.payment__text_contacts.text-big Если что-то пошло не так, сообщите нам:
    .payment__contacts
      a.payment__link.text-big(:href="phone.link") {{phone.mask}}
      a.payment__link.text-big(:href="mail.link") {{mail.mask}}
</template>

<script>
import '@/assets/styles/components/payment.sass'
import { phone, mail } from '../../assets/scripts/variables'
export default {
  name: 'ThePayment',
  data () {
    return {
      mail,
      phone,
      images: [
        { path: 'images/payment/ic_mir_white', alt: 'Логотип МИР' },
        { path: 'images/payment/ic_visa_white', alt: 'Логотип Visa' },
        { path: 'images/payment/ic_mastercard_white', alt: 'Логотип MasterCard' },
        { path: 'images/payment/ic_jcb_white', alt: 'Логотип JCB' }
      ]
    }
  }
}
</script>
